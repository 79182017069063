<template>
  <div class="evaluateCardPC">
    <!-- 月嫂信息 -->
    <div class="evaluateCardPC-caregiver">
      <div class="evaluateCardPC-caregiver-lv">
        <img src="@/assets/image/public/lv.png" />
        <span>{{ detail.servicelevel }}</span>
      </div>
      <div class="evaluateCardPC-caregiver-sign" v-if="detail.signupdata">
        <img src="@/assets/image/public/sign.png" />
      </div>
      <img class="evaluateCardPC-caregiver-avatar" :src="detail.userimage" />
      <div class="evaluateCardPC-caregiver-info">
        <div>{{ detail.username }}</div>
        <div>{{ detail.userhome }}</div>
        <div>{{ detail.userage }}岁</div>
      </div>
      <div class="evaluateCardPC-caregiver-star">
        <iconfont
          v-for="item in 5"
          :key="item"
          class="evaluateCardPC-caregiver-star-icon"
          size="32px"
          :name="item <= detail.userstar ? 'star-full' : 'star'"
        />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="evaluateCardPC-right">
      <!-- 消费者 -->
      <div class="evaluateCardPC-consumer">
        <!-- 消费者-顶部 -->
        <div class="evaluateCardPC-consumer-header">
          <!-- 消费者-名称 -->
          <div class="evaluateCardPC-consumer-name">
            <iconfont
              class="evaluateCardPC-consumer-name-icon"
              size="24px"
              name="user"
            />
            {{ detail.evlname }}
          </div>
          <!-- 消费者-来自 -->
          <div class="evaluateCardPC-consumer-from">
            {{ detail.evladdress }}
          </div>
          <!-- 消费者-时间 -->
          <div class="evaluateCardPC-consumer-datetime">
            <iconfont
              class="evaluateCardPC-consumer-datetime-icon"
              size="20px"
              name="time"
            />
            {{ detail.createtime }}
          </div>
        </div>
        <!-- 内容 -->
        <div class="evaluateCardPC-consumer-content">
          {{ detail.description }}
        </div>
      </div>
      <!-- 操作 -->
      <div class="evaluateCardPC-operation">
        <!-- 操作-标签 -->
        <div class="evaluateCardPC-operation-tag">
          <div
            class="evaluateCardPC-operation-tag-item"
            v-for="item in detail.label.split(',')"
            :key="item"
          >
            {{ item }}
          </div>
        </div>
        <!-- 操作-按钮 -->
        <div class="evaluateCardPC-operation-button">
          <div class="evaluateCardPC-operation-button-item_hollow">
            <iconfont
              class="evaluateCardPC-operation-button-item_hollow-icon"
              name="like"
              size="24px"
            />
            点赞
          </div>
          <router-link
            :to="{ name: 'evaluateDetail', query: { id: detail.id } }"
            class="evaluateCardPC-operation-button-item"
            >查看详情</router-link
          >
        </div>
      </div>
    </div>
  </div>

  <div class="evaluateCardMobile">
    <!-- 月嫂信息 -->
    <div class="evaluateCardMobile-caregiver">
      <div class="evaluateCardMobile-caregiver-lv">
        <img src="@/assets/image/public/lv.png" />
        <span>{{ detail.servicelevel }}</span>
      </div>
      <div class="evaluateCardMobile-caregiver-sign" v-if="detail.signupdata">
        <img src="@/assets/image/public/sign.png" />
      </div>
      <img
        class="evaluateCardMobile-caregiver-avatar"
        :src="detail.userimage"
      />
      <div class="evaluateCardMobile-caregiver-info">
        <div>{{ detail.username }}</div>
        <div>{{ detail.userhome }}</div>
        <div>{{ detail.userage }}岁</div>
      </div>
      <div class="evaluateCardMobile-caregiver-star">
        <iconfont
          v-for="item in 5"
          :key="item"
          class="evaluateCardMobile-caregiver-star-icon"
          size="40rpx"
          :name="item <= detail.userstar ? 'star-full' : 'star'"
        />
      </div>
      <!-- 操作-标签 -->
      <div class="evaluateCardMobile-caregiver-tag">
        <div
          class="evaluateCardMobile-caregiver-tag-item"
          v-for="item in detail.label.split(',')"
          :key="item"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="evaluateCardMobile-right">
      <!-- 消费者 -->
      <div class="evaluateCardMobile-consumer">
        <!-- 消费者-顶部 -->
        <div class="evaluateCardMobile-consumer-header">
          <!-- 消费者-名称 -->
          <div class="evaluateCardMobile-consumer-name">
            <iconfont
              class="evaluateCardMobile-consumer-name-icon"
              size="32rpx"
              name="user"
            />
            {{ detail.name }}
          </div>
          <!-- 消费者-来自 -->
          <div class="evaluateCardMobile-consumer-from">
            {{ detail.userhome }}
          </div>
          <!-- 消费者-时间 -->
          <div class="evaluateCardMobile-consumer-datetime">
            <iconfont
              class="evaluateCardMobile-consumer-datetime-icon"
              size="32rpx"
              name="time"
            />
            {{ detail.createtime }}
          </div>
        </div>
        <!-- 内容 -->
        <div class="evaluateCardMobile-consumer-content">
          {{ detail.description }}
        </div>
      </div>
      <!-- 操作 -->
      <div class="evaluateCardMobile-operation">
        <!-- 操作-按钮 -->
        <div class="evaluateCardMobile-operation-button">
          <div class="evaluateCardMobile-operation-button-item_hollow">
            <iconfont
              class="evaluateCardMobile-operation-button-item_hollow-icon"
              name="like"
              size="32rpx"
            />
            点赞
          </div>
          <router-link
            :to="{ name: 'evaluateDetail', query: { id: detail.id } }"
            class="evaluateCardMobile-operation-button-item"
            >查看详情</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news-card",
  props: {
    detail: Object,
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .evaluateCardMobile {
    display: none;
  }

  // 评论卡片
  .evaluateCardPC {
    height: 278px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    margin: 18px 0;
    display: flex;

    // 月嫂
    &-caregiver {
      width: 278px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid #eaeaea;
      position: relative;

      &-lv {
        color: #ed207b;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include cover;
          width: 34px;
          height: 34px;
        }

        span {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 20px;
          font-style: italic;
        }
      }

      // 签约
      &-sign {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 11;
      }

      // 头像
      &-avatar {
        @include cover;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        margin-top: 24px;
      }

      // 信息
      &-info {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
        color: #333333;

        div:not(:last-of-type)::after {
          content: "|";
          color: #adadad;
          display: inline-block;
          margin: 0 7px;
        }
      }

      // 星星
      &-star {
        display: flex;
        justify-content: center;
        margin-top: 5px;

        &-icon {
          margin: 0 2px;
          color: #ed207b;
        }
      }
    }

    // 右侧
    &-right {
      flex: 1;
      width: 0;
    }

    // 消费者
    &-consumer {
      border-bottom: 1px solid #eaeaea;
      background-color: #f8f8f8;
      padding: 20px;
      // height: 190px;

      // 顶部
      &-header {
        height: 40px;
        display: flex;
        align-items: center;
      }

      // 用户名
      &-name {
        font-size: 16px;
        color: #353535;
        display: flex;
        align-items: center;

        // 图标
        &-icon {
          color: #bfbfbf;
          margin-right: 10px;
        }
      }

      // 来自
      &-from {
        color: #a6a6a6;
        font-size: 16px;

        &::before {
          margin: 0 10px;
          content: "|";
          display: inline-block;
          color: #a8a8a8;
        }
      }

      // 时间
      &-datetime {
        font-size: 16px;
        color: #a6a6a6;
        margin-left: auto;
        display: flex;
        align-items: center;

        &-icon {
          margin-right: 10px;
        }
      }

      // 内容
      &-content {
        margin-top: 20px;
        color: #353535;
        font-size: 14px;
        line-height: 24px;
        height: 72px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }

    // 操作
    &-operation {
      display: flex;
      align-items: center;
      height: 104px;

      // 标签
      &-tag {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 15px;

        // 标签-元素
        &-item {
          background-color: #ed207b;
          color: #fff;
          padding: 0 10px;
          margin-right: 15px;
          height: 28px;
          border-radius: 14px;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
      }

      // 按钮
      &-button {
        display: flex;
        align-items: center;
        margin: 0 15px;
        margin-left: auto;

        // 按钮-元素
        &-item {
          background-color: #ed207b;
          color: #fff;
          height: 36px;
          border-radius: 18px;
          margin-left: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          min-width: 106px;
          box-sizing: content-box;
          cursor: pointer;

          &_hollow {
            height: 36px;
            border-radius: 18px;
            border: 1px solid #eaeaea;
            background-color: #f8f8f8;
            color: #4c4c4c;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            min-width: 106px;
            box-sizing: content-box;
            cursor: pointer;

            &-icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .evaluateCardPC {
    display: none;
  }

  // 评论卡片
  .evaluateCardMobile {
    // height: 278px;
    border: rpx(2) solid #eaeaea;
    border-radius: rpx(10);
    margin: rpx(20) 0;
    display: flex;

    // 月嫂
    &-caregiver {
      width: rpx(270);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: rpx(2) solid #eaeaea;
      position: relative;

      &-lv {
        color: #ed207b;
        position: absolute;
        left: rpx(10);
        top: rpx(10);
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include cover;
          width: rpx(34);
          height: rpx(34);
        }

        span {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: rpx(20);
          font-style: italic;
        }
      }

      // 签约
      &-sign {
        position: absolute;
        width: rpx(60);
        height: rpx(60);
        right: 0;
        top: 0;
        z-index: 11;

        img {
          @include cover;
        }
      }

      // 头像
      &-avatar {
        @include cover;
        width: rpx(128);
        height: rpx(128);
        border-radius: 50%;
        margin-top: rpx(24);
      }

      // 信息
      &-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: rpx(24);
        margin-top: rpx(10);
        color: #333333;

        div:not(:last-of-type)::after {
          content: "|";
          color: #adadad;
          display: inline-block;
          margin: 0 rpx(10);
        }
      }

      // 星星
      &-star {
        display: flex;
        justify-content: center;
        margin-top: rpx(10);

        &-icon {
          margin: 0 rpx(2);
          color: #ed207b;
        }
      }

      // 标签
      &-tag {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // height: 100%;
        padding: rpx(5);

        // 标签-元素
        &-item {
          background-color: #ed207b;
          color: #fff;
          width: rpx(120);
          margin: rpx(5);
          // padding: 0 rpx(10);
          height: rpx(40);
          border-radius: rpx(20);
          font-size: rpx(20);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    // 右侧
    &-right {
      flex: 1;
      width: 0;
    }

    // 消费者
    &-consumer {
      border-bottom: rpx(2) solid #eaeaea;
      background-color: #f8f8f8;
      padding: rpx(20);
      // height: 190px;

      // 顶部
      &-header {
        height: rpx(40);
        display: flex;
        align-items: center;
      }

      // 用户名
      &-name {
        font-size: rpx(26);
        color: #353535;
        display: flex;
        align-items: center;

        // 图标
        &-icon {
          color: #bfbfbf;
          margin-right: rpx(10);
        }
      }

      // 来自
      &-from {
        color: #a6a6a6;
        font-size: rpx(26);

        &::before {
          margin: 0 rpx(10);
          content: "|";
          display: inline-block;
          color: #a8a8a8;
        }
      }

      // 时间
      &-datetime {
        font-size: rpx(20);
        color: #a6a6a6;
        margin-left: auto;
        display: flex;
        align-items: center;

        &-icon {
          margin-right: rpx(10);
        }
      }

      // 内容
      &-content {
        // margin-top: rpx(40);
        color: #353535;
        font-size: rpx(24);
        line-height: rpx(40);
        margin-top: rpx(20);

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }

    // 操作
    &-operation {
      display: flex;
      align-items: center;
      height: rpx(100);

      // 标签
      &-tag {
        display: none;
      }

      // 按钮
      &-button {
        flex: 1;
        display: flex;
        padding: 0 rpx(10);
        align-items: center;

        // 按钮-元素
        &-item {
          margin: 0 rpx(10);
          background-color: #ed207b;
          color: #fff;
          height: rpx(50);
          border-radius: rpx(25);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: rpx(24);
          flex: 1;
          box-sizing: content-box;
          cursor: pointer;

          &_hollow {
            margin: 0 rpx(10);
            height: rpx(50);
            border-radius: rpx(25);
            border: rpx(2) solid #eaeaea;
            background-color: #f8f8f8;
            color: #4c4c4c;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: rpx(24);
            flex: 1;
            box-sizing: content-box;
            cursor: pointer;

            &-icon {
              margin-right: rpx(10);
            }
          }
        }
      }
    }
  }
}
</style>
